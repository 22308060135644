<template>
  <div class="wrapper">
    <div class="row" v-if="OnlineOnly">
      <img :src="'/assets/svg/error.svg'" class="m5" width="23px" height="23px" alt="error">
      <span>An error occured while loading the component. </span>
      <div>
        <span class="link" @click="reload">Try again</span> /
        <span class="link" @click="close"> Close</span>
      </div>
    </div>
    <div class="row" v-if="OfflineOnly">
      <img :src="'/assets/svg/error.svg'" class="m5" width="23px" height="23px" alt="error">
      <span>Component couldn't be loaded due to lack of network connectivity.</span>
      <span class="link" @click="close">Close</span>
    </div>
  </div>
</template>

<script>
import VueOfflineMixin from 'vue-offline/mixin'

export default {
  name: 'LoadingError',
  mixins: [VueOfflineMixin],
  mounted () {
    this.$on('online', () => {
      this.reload()
    })
  },
  methods: {
    reload () {
      this.$emit('reload')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #ff847b;
  color: white;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
.row {
  justify-content: center;
  text-align: center;
}
div {
  text-align: center;
  line-height: 25px;
  & > * {
    width: 100%;
  }
}
</style>
